import { getColorPaletteAtIndex } from "config";
import { Portfolio } from "contorller/portfolio/types";
import { compact, orderBy } from "lodash";
import Matrix from "ml-matrix";
import { useTranslation } from "react-i18next";
import { INVESTED_DEFLATED, INVESTED_FIXED_RATE } from "simulator/types";
import { getAbsoluteYield, getLastAmount } from "./earnTable";

export function useGetLegendItems() {
  const { t } = useTranslation();
  return (
    isRealPic: boolean,
    data: Matrix,
    fundsData: Matrix[],
    portfolio?: Portfolio
  ): FundLegendItem[] => {
    if (portfolio == null) {
      return [];
    }
    const p: FundLegendItem = {
      id: portfolio.id,
      name: portfolio.name,
      type: "portfolio",
      amount: getLastAmount(data),
      yield: getAbsoluteYield(data),
      color: getColorPaletteAtIndex(0),
    };

    const realPic: FundLegendItem[] = isRealPic
      ? [
          {
            id: "invested",
            name: t("Investito"),
            type: "invested",
            amount: getLastAmount(data, INVESTED_DEFLATED),
            yield: 0, //getAbsoluteYield(data, INVESTED, INVESTED_DEFLATED),
            color: getColorPaletteAtIndex(1),
          },
          {
            id: "invested-fixed-rate",
            name: t("Investito Tasso Fisso"),
            type: "invested",
            amount: getLastAmount(data, INVESTED_FIXED_RATE),
            yield: 0,
            color: getColorPaletteAtIndex(2),
          },
        ]
      : [];

    const funds: FundLegendItem[] = orderBy(
      portfolio.funds.map((f, index) => ({
        id: f.id,
        name: f.fund.name,
        type: "fund",
        amount: getLastAmount(fundsData[index]),
        yield: getAbsoluteYield(fundsData[index]),
        color: getColorPaletteAtIndex(index + 3),
      })),
      "yield",
      "desc"
    );
    // console.log(portfolio.funds);
    return compact([p, ...realPic, ...funds]);
  };
}

export interface FundLegendItem {
  id: string;
  name: string;
  type: "fund" | "portfolio" | "invested";
  yield: number;
  color: string;
  amount: number;
  nameAccessories?: string[];
}
