import { config } from "config";
import { JsonResponseError, Sum, store } from "contorller";
import { authController } from "contorller/auth/controller";
import { Auther } from "contorller/auth/types";
import {
  absoluteEarge,
  annualEarge,
  maxNumber,
  minNumber,
} from "contorller/utils";
import Papa from "papaparse";
import {
  ControllerSimulation,
  IISRequest,
  IISResponse,
  Simulation,
} from "../types";
import { decodeFromBlob } from "../utils";
import { IISActions, MonetaryFund } from "./redux";

export type IISController = ControllerSimulation<IISRequest, IISResponse>;
export const iisController = (): IISController =>
  concreteIISController.getInstance(authController().getAuther());

export class concreteIISController
  implements ControllerSimulation<IISRequest, IISResponse>
{
  protected auther: Auther;
  private ws?: WebSocket;

  private initialReconnectDelay = 1000;
  private currentReconnectDelay = this.initialReconnectDelay;
  private maxReconnectDelay = 16000;

  open(e: Event): void {
    console.log("Connessione stabilita con IIS");
    store.dispatch(IISActions.websocketIsReady());
    this.currentReconnectDelay = this.initialReconnectDelay;
  }

  retry(): {
    
  }

  close(e: Event): void {
    console.log("IIS chiusa connessione ", e);
    store.dispatch(IISActions.websocketIsNotReady());
    this.connect(2);
  }
  error(e: Event): void {
    console.log("IIS errore connessione ", e);
    store.dispatch(IISActions.websocketIsNotReady());
    store.dispatch(
      IISActions.error({
        error: {
          code: "ws",
          error: "Errore imprevisto",
          status: "error",
        },
      })
    );
    this.connect(2);
  }

  iisResponse: IISResponse;
  async message(e: MessageEvent<Blob | string>): Promise<void> {
    //const sims: Simulation[] | JsonResponseError = JSON.parse(e.data)
    //const sims: Simulation[] | JsonResponseError = Unmarshal(l)
    // console.log("decompressione in:", Date.now()-t)
    //let t = Date.now()
    let sims: Simulation[] = [];
    try {
      if (typeof e.data !== "string") {
        sims = await decodeFromBlob<Simulation[]>(e.data);
      } else {
        let error: JsonResponseError = JSON.parse(e.data as string);
        store.dispatch(IISActions.error({ error }));
        return;
      }
    } catch (e) {
      console.error(e);
    }
    if (Array.isArray(sims) && sims.length > 0)
      (sims as Simulation[]).forEach((sim, index) => {
        if (!sim.index) {
          sim.index = 0;
          store.dispatch(IISActions.startSimulation({ total: sim.total }));
          store.dispatch(
            IISActions.tableEarn({
              table: {
                annual: {
                  min: 0,
                  max: 0,
                  avg: 0,
                },
                absolute: {
                  min: 0,
                  max: 0,
                  avg: 0,
                },
              },
            })
          );
        }
        let cvu =
          Sum(sim.snaps[sim.snaps.length - 1].funds) +
          (sim.snaps[sim.snaps.length - 1].moneyExchange || 0);
        let cvi = Sum(sim.snaps[0].funds);
        let y =
          new Date(sim.snaps[sim.snaps.length - 1].unix * 1000).getFullYear() -
          new Date(sim.snaps[0].unix * 1000).getFullYear();

        sim.annual = annualEarge(cvu, cvi, y * 365);
        sim.absolute = absoluteEarge(cvu, cvi);
        //console.log("iis Annuale",cvu, cvi, y, sim.annual, sim.absolute)
        if (this.iisResponse && this.iisResponse.request) {
          this.iisResponse.simulations.push(sim);
          // se indice +1 è uguale al totale significa che è l'ultima simulazione che manda il backend.
          if (sim.index + 1 === sim.total) {
            if (this.iisResponse) {
              store.dispatch(IISActions.endSimulation());
              let annuals = this.iisResponse.simulations.map<number>(
                (e) => e.annual
              );
              let absolutes = this.iisResponse.simulations.map<number>(
                (e) => e.absolute
              );

              // console.log("IIS", this.iisResponse);

              store.dispatch(
                IISActions.tableEarn({
                  table: {
                    years: this.iisResponse.request.durationInvestment,
                    annual: {
                      min: minNumber(annuals),
                      max: maxNumber(annuals),
                      avg: Sum(annuals) / annuals.length,
                    },
                    absolute: {
                      min: minNumber(absolutes),
                      max: maxNumber(absolutes),
                      avg: Sum(absolutes) / absolutes.length,
                    },
                  },
                })
              );
            }
          }

          store.dispatch(IISActions.updateSimulation({ index: sim.index }));
        }
      });
    store.dispatch(IISActions.ok());
  }

  constructor(auther: Auther) {
    this.auther = auther;
    store.dispatch(IISActions.websocketIsNotReady());
    this.connect(0);
    this.iisResponse = { simulations: [] };
    let monetaries: MonetaryFund[] = [];

    if (this.auther.isAdmin()) {
      monetaries.push({
        id: "iis_liquidity",
        name: "Fondo Virtuale in liquidità",
        isin: "",
      });
    }

    

    monetaries.push({
      isin: "IE0030608859",
      defaultFund: true,
      id: "9c422873-fd8f-5256-89ea-0f601682818b",
      name: "Mediolanum BB Euro Fixed Income LA Euro",
    });
    monetaries.push({
      id: "8989e43b-b123-58f9-b9ca-857e09ea7738",
      name: "Mediolanum Challenge Liquidity LA EUR",
      isin: "IE0004878637",
    });
    monetaries.push({
      id: "1fa76047-e015-5cdd-94e7-c8a5ce5066e6",
      name: "Mediolanum Easy Fund",
      isin: "MEDEASY",
    });
    
    if (this.auther.isSpanishUser()) {
      monetaries = [
        {
          id: "9119d07c-7fef-59b4-b36f-b82ce12ee457",
          name: "Mediolanum Activo LA",
          isin: "ES0165127004",
          defaultFund: true,
        },
        {
          isin: "IE0030608859",
          id: "9c422873-fd8f-5256-89ea-0f601682818b",
          name: "Mediolanum BB Euro Fixed Income LA Euro",
        },
        {
          id: "8989e43b-b123-58f9-b9ca-857e09ea7738",
          name: "Mediolanum Challenge Liquidity LA EUR",
          isin: "IE0004878637",
        },
        {
          id: "1fa76047-e015-5cdd-94e7-c8a5ce5066e6",
          name: "Mediolanum Easy Fund",
          isin: "MEDEASY",
        }
      ];
    }

    if (this.auther.isGermanUser()) {
      monetaries = [
        {
          id: "66a75675-9e26-5d88-a6e0-7887426095f0",
          name: "Mediolanum Easy Fund 3.0",
          defaultFund: true,
          isin: "MEDEASYD",
        },
        {
          id: "a6c6aa6c-4bf7-58a4-8021-9cee3b5da775",
          name: "Mediolanum Activo SA",
          isin: "ES0165127038",
        },
        {
          id: "8989e43b-b123-58f9-b9ca-857e09ea7738",
          name: "Mediolanum CH Liquidity Euro LA Cap EUR",
          isin: "IE0004878637",
        },
        {
          id: "9c422873-fd8f-5256-89ea-0f601682818b",
          name: "Mediolanum BB Euro Fixed Income LA Cap EUR",
          isin: "IE0030608859",
        },
      ];
    }

    store.dispatch(IISActions.loadMonetaryFunds({ funds: monetaries }));
  }
  getName(): string {
    throw new Error("Method not implemented.");
  }
  csvDownload() {
    let index = store.getState().iis.indexSimulationSelected;
    if (!this.iisResponse.request) throw new Error("non ho la richiesta iis ");
    if (this.iisResponse.simulations.length < index)
      throw new Error("questo indice non esiste");
    let rows: string[][] = [];
    let header = ["Data", "Soldi in scambio", "Monetario Controvalore"];

    this.iisResponse.request.funds.forEach((e) =>
      header.push(`${e.name} (Controvalore)`)
    );
    header.push("Monetario Chiusura");
    this.iisResponse.request.funds.forEach((e) =>
      header.push(`${e.name} (Chiusura)`)
    );

    this.iisResponse.request.funds.forEach((e) =>
      header.push(`${e.name} (VMC stepin)`)
    );
    this.iisResponse.request.funds.forEach((e) =>
      header.push(`${e.name} (VMC stepout)`)
    );

    rows.push(header);

    this.iisResponse.simulations[index].snaps.forEach((day) => {
      let row: string[] = [];
      let d = new Date(day.unix * 1000);
      row.push(`${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`);
      row.push((day.moneyExchange || 0).toString().replace(".", ","));
      row.push(day.funds[0].toString().replace(".", ","));
      row.push(
        ...day.funds
          .slice(1, day.funds.length)
          .map<string>((f) => f.toString().replace(".", ","))
      );
      row.push(day.closes[0].toString().replace(".", ","));
      row.push(
        ...day.closes
          .slice(1, day.closes.length)
          .map<string>((f) => f.toString().replace(".", ","))
      );
      row.push(
        ...(day.vmcIn || [])
          .slice(1, day.vmcIn?.length)
          .map<string>((f) => f.toString().replace(".", ","))
      );
      row.push(
        ...(day.vmcOut || [])
          .slice(1, day.vmcOut?.length)
          .map<string>((f) => f.toString().replace(".", ","))
      );
      rows.push(row);
    });
    let dataStr = "data:text/csv;charset=utf-8,";
    dataStr += encodeURIComponent(Papa.unparse(rows));
    var dlAnchorElem: HTMLAnchorElement = document.createElement("a");
    dlAnchorElem.setAttribute("href", dataStr);
    dlAnchorElem.setAttribute("download", "iis.csv");
    dlAnchorElem.click();
  }
  jsonDownload() {
    var dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(this.iisResponse));
    var dlAnchorElem: HTMLAnchorElement = document.createElement("a");
    dlAnchorElem.setAttribute("href", dataStr);
    dlAnchorElem.setAttribute("download", "iis.json");
    dlAnchorElem.click();
  }
  clearResult(id: string): void {
    this.iisResponse = { simulations: [] };
  }

  clearAllResults(): void {
    store.dispatch(IISActions.reset());
    this.iisResponse = { simulations: [] };
  }
  getAllResults(): IISResponse[] {
    if (this.iisResponse.simulations.length > 0) return [this.iisResponse];
    return [];
  }
  getResult(id: string): IISResponse | undefined {
    if (this.iisResponse?.simulations.length === 0) return undefined;
    return this.iisResponse;
  }

  timeOut?: NodeJS.Timeout;
  connect(seconds: number) {
    if (!this.timeOut) {
      console.log("IIS -  sto provando a connettermi ", seconds);
      this.timeOut = setTimeout(() => {
        this.auther
          .getToken()
          .then((token) => {
            this.ws = new WebSocket(config().WS + "/iis/ws?token=" + token);
            this.ws.addEventListener("open", (e) => this.open(e));
            this.ws.addEventListener("error", (e) => this.error(e));
            this.ws.addEventListener("message", (e) => this.message(e));
            this.ws.addEventListener("close", (e) => this.close(e));
          })
          .catch(console.error)
          .finally(() => {
            if (this.timeOut) {
              clearTimeout(this.timeOut);
              this.timeOut = undefined;
            }
          });
      }, seconds * 1000);
    }
  }

  private static _instance: concreteIISController;
  public static getInstance(auther: Auther): concreteIISController {
    if (!concreteIISController._instance) {
      concreteIISController._instance = new concreteIISController(auther);
    }
    return concreteIISController._instance;
  }

  async calc(id: string, request: IISRequest): Promise<void> {
    if (!this.ws) {
      throw new Error("devi settare WS prima di richiedere una simulazione");
    }
    // Start the simulation as fast as we click calc
    store.dispatch(IISActions.startSimulation({}));
    this.iisResponse = { simulations: [], request: { ...request } };
    this.ws.send(JSON.stringify(request));
  }
}
