import { Colors } from "@blueprintjs/core";
import Color from "color";
import { PortfolioFund } from "contorller/portfolio/types";
import { TFunction } from "i18next";
import { DateTime } from "luxon";
import type { Datum, PlotData } from "plotly.js";

interface SimulationOptions {
  selectedHistogramIndex?: number;
  showMeanPerfomance?: boolean;
  opacity?: number;
  isPrinting?: boolean;
  window?: { start: DateTime; end: DateTime };
}

export const isSafari =
  /constructor/i.test((window as any).HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !(window as any)["safari"] ||
      (typeof (safari as any) !== "undefined" &&
        (window as any)["safari"].pushNotification)
  );

export const A4_RATIO = 1.4142;
export const PRINTABLE_GRAPH_WIDTH = isSafari ? 1095 : 1170;
export const PRINTABLE_STAT_TRIO = 768;
export const PRINTABLE_ACCORDION_WIDTH = isSafari ? 1100 : 1175;
export const PRINTABLE_HEIGHT = PRINTABLE_GRAPH_WIDTH * A4_RATIO;

export function createSimulationHistograms(
  histogram: { absolute: number; start: Date; end: Date }[],
  colors: {
    positive: (selected: boolean) => string;
    negative: (selected: boolean) => string;
  },
  t: TFunction,
  options?: SimulationOptions
): Partial<PlotData>[] {
  const totalCount = histogram.length;
  const hiddenOpacity = options?.opacity ?? 0.5;
  const positiveCount = histogram.filter((f) => f.absolute >= 0).length;
  const negativeCount = totalCount - positiveCount;
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  } as Intl.DateTimeFormatOptions;

  let xWindowOpacityStart = options?.window?.start?.toJSDate();
  let xWindowOpacityEnd = options?.window?.end?.toJSDate();
  let opacity = new Array(histogram.length);
  let x = new Array(histogram.length);
  let yPositive = new Array(histogram.length);
  let textPositive = new Array(histogram.length);
  let yNegative = new Array(histogram.length);
  let textNegative = new Array(histogram.length);
  let yMeanSum = 0;
  let hasSelectedIndex = options?.selectedHistogramIndex != null;
  histogram.forEach((e, index) => {
    x[index] = e.start;
    opacity[index] =
      !hasSelectedIndex || options?.selectedHistogramIndex === index
        ? 1.0
        : 0.65;
    if (xWindowOpacityStart && xWindowOpacityEnd) {
      if (e.start < xWindowOpacityStart || e.end > xWindowOpacityEnd) {
        opacity[index] = hiddenOpacity / 2.0;
      }
    }
    yPositive[index] = e.absolute >= 0 ? e.absolute * 100 : null;
    textPositive[index] = `${(e.absolute * 100).toFixed(
      2
    )}% ( ${e.start.toLocaleDateString(
      "it",
      dateOptions
    )} - ${e.end.toLocaleDateString("it", dateOptions)} )`;
    yNegative[index] = e.absolute < 0 ? e.absolute * 100 : null;
    textNegative[index] = `${(e.absolute * 100).toFixed(
      2
    )}% ( ${e.start.toLocaleDateString(
      "it",
      dateOptions
    )} - ${e.end.toLocaleDateString("it", dateOptions)} )`;
    yMeanSum += e.absolute * 100;
  });

  // const x = histogram.map((e) => e.start);

  const meanPerfomance = yMeanSum / histogram.length;

  const otherPlots: Partial<PlotData>[] = options?.showMeanPerfomance
    ? [
        {
          type: "scatter",
          mode: "lines",
          x,
          line: {
            color: Color(Colors.LIME3).alpha(0.8).toString(),
            dash: "dash",
          },
          showlegend: false,
          hoverinfo: "none",
          y: new Array(histogram.length).fill(meanPerfomance),
        },
      ]
    : [];

  const caseLabel = (count: number) => (count > 1 ? t("casi") : t("caso"));
  const positiveLabel = (count: number) =>
    count > 1 ? t("Positivi") : t("Positivo");
  const negativeLabel = (count: number) =>
    count > 1 ? t("Negativi") : t("Negativo");
  return [
    ...otherPlots,
    {
      type: "bar",
      name: `${positiveLabel(positiveCount)} ${positiveCount} ${caseLabel(
        positiveCount
      )} (<span style="color: ${colors.positive(true)};">${(
        (positiveCount / totalCount) *
        100
      ).toFixed(1)}%</span>)`,
      x,
      y: yPositive,
      textposition: "none",
      hoverinfo: options?.isPrinting ? "none" : "text",
      text: textPositive,
      marker: {
        opacity,
        color: colors.positive(true),
      },
    },
    {
      type: "bar",
      name: `${negativeLabel(negativeCount)} ${negativeCount} ${caseLabel(
        negativeCount
      )} (<span style="color: ${colors.negative(true)};">${(
        (negativeCount / totalCount) *
        100
      ).toFixed(1)}%</span>)`,
      x,
      y: yNegative,
      hoverinfo: options?.isPrinting ? "none" : "text",
      textposition: "none",
      text: textNegative,
      marker: {
        opacity,
        color: colors.negative(true),
      },
    },
  ];
}

export function getColorFn(kind: "positive" | "negative") {
  if (kind === "positive") {
    return (selected: boolean) =>
      Color(Colors.BLUE3)
        .alpha(selected ? 1 : 0.7)
        .string();
  } else {
    return (selected: boolean) =>
      Color(Colors.RED3)
        .alpha(selected ? 1 : 0.7)
        .string();
  }
}

export function getRecurrencyInvString(monthFrequency: number): string {
  switch (monthFrequency) {
    case 0:
    case 1:
      return "Mensile";
    case 2:
      return "Trimestrale";
    case 5:
      return "Semestrale";
    case 11:
      return "Annuale";
    case 15:
      return "Quindicinale";
    default:
      return "";
  }
}

export function getStartYear(startUnix: number): string {
  if (startUnix == 0) {
    return "dall'inizio";
  }
  var t = new Date(startUnix * 1000);
  return t.getFullYear().toString();
}

export function getEndYear(startUnix: number): string {
  if (startUnix == 0) {
    return "alla fine";
  }
  var t = new Date(startUnix * 1000);
  return t.getFullYear().toString();
}

export function developCuttedHistoricalFromGraphLine(graphLine: Partial<PlotData>, cuttingStartDate: DateTime, cuttingEndDate : DateTime): number[]{
  const developedHist : {date: DateTime, value: number}[] = [];
  const undevelopedHist : {x: Datum, y: Datum}[] = 
    ((graphLine.x ?? []) as Datum[]).map((value, index) => { return {x: value, y: ((graphLine.y ?? []) as Datum[])[index]}});
  
  for (const graphPoint of undevelopedHist) {
    const currentDate = DateTime.fromMillis((graphPoint.x ?? [])?.valueOf() as number ?? 0);
    if (currentDate >= cuttingStartDate && currentDate < cuttingEndDate) {
      if (developedHist.length <= 0) {
        developedHist.push({date: currentDate, value: graphPoint.y as unknown as number});
        continue;
      }
      while (currentDate.diff(developedHist[developedHist.length-1].date, "days").days >= 2) { // >1 pick also 1d 1h 
        developedHist.push({date: developedHist[developedHist.length-1].date.plus({days:1}), value: developedHist[developedHist.length-1].value});
      } 
      developedHist.push({date: currentDate, value: graphPoint.y as unknown as number});
      
    } else if (currentDate > cuttingEndDate) {
      break;
    }
  }


  return developedHist.map(dh => dh.value)
}

export function getHistogramPercentage(min: number, max: number, value: number, expandPercentage?: number): number {
  const exMin = min - Math.abs(min * (expandPercentage ?? 0))
  // const exMax = max + Math.abs(max * (expandPercentage ?? 0))
  return ((value - exMin) / (max - exMin)) * 100;
}

export function getOngoingCharges(funds: PortfolioFund[]): number {
  const ongoingCharges = funds.reduce((sum, fund) => {
    if (fund.percentage > 0) {
      return sum + (fund.percentage * ((fund.fund.valueCost ?? 0)/100) );
    }
    return sum;
  }, 0);
  return ongoingCharges;
}

export function getValueGradientPercentage(value: number, allValues: number[], betterHigher:boolean): number {
  const quote = 100 / (allValues?.length ?? 1);
  const index = allValues.toSorted((a, b) => betterHigher ? a - b : b - a).findIndex((v) => v === value);
  return index*quote
}