import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PortfolioWithFunction } from "contorller/portfolio/types";

export interface ConfrontableState {
  groups: PortfolioWithFunction[];
  loading: boolean;
  isProxyEnabled: boolean;
}

const comparisonState = createSlice({
  name: "confrontable",
  initialState: {
    loading: false,
    isProxyEnabled: true,
    groups: [],
  } as ConfrontableState,
  reducers: {
    cleanAllPortfolio: (state) => {
      state.groups = [];
    },
    addPortfolio: (state, action: PayloadAction<{ portfolio: PortfolioWithFunction }>) => {
      const portfolio: PortfolioWithFunction = {...action.payload.portfolio};
      if (portfolio.compareId == null || portfolio.compareId === "") {
        portfolio.compareId = `${portfolio.id}-${(Math.random() + 1).toString(36).substring(7)}`
      }
      state.groups.push({
        ...portfolio,
        type: "percentage",
      });
    },
    enableProxies(state, _) {
      state.groups.forEach?.((d) => {
        d.funds.forEach((f) => {
          f.proxed = f.fund.history.proxy != null ? true : false;
        });
      });
      state.isProxyEnabled = true;
    },
    disableProxies(state, _) {
      state.groups.forEach?.((d) => {
        d.funds.forEach((f) => {
          f.proxed = false;
        });
      });
      state.isProxyEnabled = false;
    },
    updatePortfolio: (
      state,
      action: PayloadAction<{ portfolio: PortfolioWithFunction }>
    ) => {
      const { portfolio } = action.payload;
      const previousIndex = state.groups.findIndex(
        (d) => d.compareId === portfolio.compareId
      );
      if (previousIndex >= 0) {
        state.groups[previousIndex] = portfolio;
      }
    },
    removePortfolio: (state, action: PayloadAction<{ ID: string }>) => {
      state.groups = state.groups.filter((e) => e.compareId !== action.payload.ID);
    },
    portfolioError: (
      state,
      action: PayloadAction<{ id: string; error: string }>
    ) => {
      for (let i of state.groups) {
        if (i.compareId === action.payload.id) {
          i.error = { error: action.payload.error, code: "", status: "" };
          break;
        }
      }
    },
  },
});

export const ConfrontableActions = comparisonState.actions;
export const ConfrontableReducer = comparisonState.reducer;
