import { PaymentIntentResult, SetupIntentResult } from "@stripe/stripe-js";
import { JsonResponseError } from "contorller";

export type Loadable<T> = T & { loading?: boolean };

export interface signUpRequest {
  name: string;
  surname: string;
  email: string;
  password: string;
  birthday: string;
  phone: string;
  createAt: number;
  pec: string;
  fiscalCode: string;
  vatNumber: string;
  sdi: string;
  address: {
    cap: string;
    city: string;
    region: string;
    province: string;
    street: string;
    nation: string;
  };
}

export interface Session {
  admin: boolean;
  subscription: number;
}

export interface signResponse {
  user: User;
  token: string;
  session: Session;
}

export interface address {
  cap: string;
  city: string;
  province: string;
  region: string;
  nation: string;
  street: string;
}

export enum UserStatus {
  NIL = 0,
  ACTIVE = 1,
  DEACTIVATE = 2,
  TRIAL = 3,
  TRIAL_ACCEPT = 4,
  FUTURE_DEACTIVATE = 5,
  RENEW_FAIL = 6,
  FIRST_PAY_FAIL = 7,
}

export enum UserState {
  ACTIVE = "active",
  DEACTIVATE = "deactivate",
  TRIAL = "trial",
  TRIAL_ACCEPT = "trialAccept",
  FUTURE_DEACTIVATE = "futureDeactivate",
  RENEW_FAIL = "renewFail",
  FIRST_PAY_FAIL = "firstPayFail",
}
export interface UserBillingInfo {
  productId: string;
  priceId: string;
  requireCard: boolean;
}

export enum UserBillingPlan {
  BASE = 0,
  PREMIUM = 1,
  PREMIUM_TEST = 2,
  PREMIUM_PLUS = 3,
}

export enum FidelityState {
  NONE = 0,
  STANDARD = 1,
}

export interface User {
  name: string;
  surname: string;
  uid: string;
  createAt: number;
  billingPlanEnum: UserBillingPlan;
  billingPlanLastUpdate: number;
  fidelityEnum: FidelityState;
  fidelityLastCheck?: number;
  status: UserStatus | string;
  active: boolean;
  address: address;
  phone: string;
  fiscalCode: string;
  betaTester?: boolean;
  vatNumber: string;
  trialUsed?: boolean;
  pec: string;
  bank?: string;
  email: string;
  admin: boolean;
  lastLogin?: number;
  sdi: string;
  password?: string;
  groupId?: string;
  userCode?: string;
  region?: string;
  subRegion?: string;
  stripeCustomer?: string;
  stripeSubscription?: string;
  stripeCoupon?: string;
  stripePromoCode?: string;
  trialAcceptedUnix?: number;
  stripeCouponDiscount?: string;
  period?: number;
  deadlinePeriod?: number;
  revokeSubscription: boolean;
  premiumTestExpiration?: string;
  v1Id?: string;
  v1RefCode?: string;
  v1RefDiscount?: string;
  v1RefName?: string;
}

export interface PaymentMethods {
  customerId: string;
  defaultMethod: PaymentMethod;
  linkToCustomer: string;
  methods: [PaymentMethod];
}

export interface PaymentMethod {
  brand: string;
  expMonth: number;
  expYear: number;
  id: string;
  isDefault: boolean;
  last4: string;
  type: string;
  uid: string;
}

export type TokenHeader = { Authorization: string };
export interface Auther {
  getToken: () => Promise<string>;
  getHeaderToken: () => Promise<TokenHeader>;
  isAdmin(): boolean;
  isGermanUser(): boolean;
  isSpanishUser(): boolean;
}

export interface RequestPaymentParams {
  productId: string;
  promoCode?: string;
  coupon?: string;
  priceId: string;
  error?: Error;
}

export interface PaymentResponse {
  alreadyActive?: boolean;
  clientSecret: string;
  subscriptionId: string;
  requireCard?: boolean;
  method: "payment" | "setup";
  status: string;
  code: string;
  error?: string;
  response: signResponse;
}

export interface Coupon {
  id: string;
  percent_off: number;
  valid: boolean;
  amount_off: number;
  duration: "repeating" | "forever";
  duration_in_months?: number;
  applies_to?: {
    products?: string[];
  };
  metadata: {
    trial?: number;
  };
}

export interface PromoCode {
  id: string;
  active: boolean;
  code: string;
  metadata: {
    trial?: number;
  };
  coupon: Coupon;
}

export type ResetPasswordResponse = { status: "ok" } | JsonResponseError;

export interface AuthController {
  signInWithCode(code: string): Promise<void>;
  signIn(email: string, password: string): Promise<void>;
  signUp(request: signUpRequest): Promise<void>;
  signOut(forced: boolean): Promise<void>;
  getUser(): User | undefined;
  getAuther(): Auther;
  hook(): Promise<void>;
  refreshInfo(): Promise<void>;
  refreshToken(): Promise<void>;
  refreshInfo(): Promise<void>;
  update(user: User): Promise<void>;
  revoke(): Promise<void>;
  requestForgotPassword(email: string): Promise<ResetPasswordResponse>;
  completeForgotPassword(
    code: string,
    password: string
  ): Promise<ResetPasswordResponse>;
  getPromoCode(coupon: string): Promise<PromoCode | null>;
  cancelPromoCode(): void;
  requestListCodesProducts(): Promise<void>;
  requestPayment(params: RequestPaymentParams): Promise<PaymentResponse | null>;
  changePlan(params: RequestPaymentParams): Promise<PaymentResponse | null>;
  completePayment(
    intent: PaymentIntentResult | SetupIntentResult
  ): Promise<void>;
}
