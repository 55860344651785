import { FundHistory } from "contorller/history/db";
import { Matrix } from "ml-matrix";

export enum OperationKind {
  BUY = 1,
  SELL = -1,
}

export interface Operation {
  kind: OperationKind;
  amount: number;
  date: Date;
  fundId: string;
}

export interface FundData {
  startDate: Date;
  endDate: Date;
  closes: number[];
}

export interface RawOperation {
  kind: OperationKind;
  amount: number;
  index: number;
}

export type SimulatorFunction = (
  data: FundData,
  operations: Operation[]
) => Matrix;

export interface RelativeOperation {
  amount: number;
  fundId: string;
  kind: OperationKind;
  interval: {
    amount: number;
    kind: "years" | "months" | "days";
  };
}

export enum WorkerMessageType {
  RUN_PIC,
  PIC_RESULT,
}

export interface RawWorkerMessage {
  id: string;
  type: WorkerMessageType;
}

export interface RunPicWorkerMessage extends RawWorkerMessage {
  type: WorkerMessageType.RUN_PIC;
  payload: {
    data: FundHistory[];
    operations: Operation[];
    deflateFundId?: string;
    annualCommission?: number;
    onlyBounds?: boolean;
    fixedRate?: number;
  };
}

export interface PicResultWorkerMessage extends RawWorkerMessage {
  type: WorkerMessageType.PIC_RESULT;
  payload: {
    funds: {
      rows: number;
      columns: number;
      data: number[][];
    }[];
    result: {
      rows: number;
      columns: number;
      data: number[][];
    };
  };
}

export type WorkerMessage = RunPicWorkerMessage | PicResultWorkerMessage;

export function convertResult(msg: WorkerMessage): Matrix {
  if (msg.type !== WorkerMessageType.PIC_RESULT) {
    throw new Error("Unexpected message type");
  }
  return new Matrix(msg.payload.result.data);
}
export function convertFunds(msg: WorkerMessage): Array<Matrix> {
  if (msg.type !== WorkerMessageType.PIC_RESULT) {
    throw new Error("Unexpected message type");
  }
  return msg.payload.funds.map(f=>new Matrix(f.data));
}

export function convertSingleFundsResult(
  msg: WorkerMessage,
  amount: number = 10000
): Matrix[] {
  if (msg.type !== WorkerMessageType.PIC_RESULT) {
    throw new Error("Unexpected message type");
  }
  return msg.payload.funds.slice(0, -1).map((d) => {
    const matrix = new Matrix(d.data);
    const scale = amount / matrix.get(AMOUNT, 0);
    return matrix.mulRow(AMOUNT, scale).mulRow(INVESTED, scale);
  });
}

export function commissionsSum(commissions: Array<number|undefined|null>): number | undefined {
  const temp = commissions.filter((v) => v)
  if (temp.length === 0) {
    return undefined;
  }
  if (temp.length === 1) {
    return temp[0] as number;
  }
  let value = 100;
  temp.map((v) => value = (value * (100-v!))/100)
  return 100-value;
}

export const CLOSES = 0;
export const OPERATIONS = 1;
export const QUOTAS = 2;
export const INVESTED = 3;
export const INVESTED_FIXED_RATE = 4;
export const INVESTED_DEFLATED = 5;
export const AMOUNT = 6;
export const PERCENTAGE = 7;
export const DATES = 7;
export const LAST_ROW = DATES;
export const FUNCTION_DATA = 8;
export const FUNCTION_FILLER = 9;
