import firebase from "firebase/app";
import "firebase/auth";

const firebaseStage = {
	apiKey: "AIzaSyAmMa_DmjmZsUO6b3YNMJEV2BB_h4jR8dc",
	authDomain: "empirich-dev.firebaseapp.com",
	projectId: "empirich-dev",
	storageBucket: "empirich-dev.appspot.com",
	messagingSenderId: "927964726320",
	appId: "1:927964726320:web:61c59aa6be64eae9d519f5",
	measurementId: "G-T7E5Z6NK9P",
};

const firebaseProd = {
	apiKey: "AIzaSyDwiuEKc5RobhqQ-GSSqHl38xZrZSamJr0",
	authDomain: "empirich-2.firebaseapp.com",
	projectId: "empirich-2",
	storageBucket: "empirich-2.appspot.com",
	messagingSenderId: "351351687034",
	appId: "1:351351687034:web:9ecb28fd2f8a1d664e78c3",
	measurementId: "G-YXNZ5ETVPX",
};

const firebaseMes = {
	apiKey: "AIzaSyCbslV4n6Z1n1NEqscyXJtVt-Qd0dFvV1w",
	authDomain: "encoded-source-277806.firebaseapp.com",
	databaseURL: "https://encoded-source-277806.firebaseio.com",
	projectId: "encoded-source-277806",
	storageBucket: "encoded-source-277806.appspot.com",
	messagingSenderId: "387332174380",
	appId: "1:387332174380:web:a3d7d900f5403f6d709789",
	measurementId: "G-461SD3R6YJ",
};

export const firebaseApp = () => {
	switch (getStatusStage()) {
		case "production":
			// console.log("[firebase] prod init");
			return firebase.initializeApp(firebaseProd);
		case "test":
			return firebase.initializeApp(firebaseStage);
		case "stage":
			// console.log("[firebase] stage init");
			return firebase.initializeApp(firebaseStage);
		case "mes":
			// console.log("[firebase] mes init");
			return firebase.initializeApp(firebaseProd);
		case "localhost":
			return firebase.initializeApp(firebaseStage);
	}
};

export const MAINANTENCE_MODE = false;

export enum SpainEnterpriseTool {
	MES = "es_en_mes",
	PAC = "es_en_pac",
	INBEST = "es_en_inbest",
    DC = "es_en_dc",
}

export enum Feature {
	PREPAID = "PREPAID",
	PREMIUM = "PREMIUM",
	GARFIELD = "GARFIELD",
	DANIKA_SEMI_PRO = "DANIKA_SEMI_PRO",
	SAVED_QUERIES = "SAVED_QUERIES",
	PREMIUM_BANNER = "PREMIUM_BANNER",
	ONGOING_CHARGES = "ONGOING_CHARGES",
	LISTS = "LISTS",
	SIMULATION_METRICS = "SIMULATION_METRICS",
	SINGLE_SESSION = "SINGLE_SESSION",
	BANKS = "BANKS",
	SCORE = "SCORE",
	HINT_OVERLAY = "HINT_OVERLAY",
	BOOKMARKS = "BOOKMARKS",
	MATOMO = "MATOMO",
	UP_AND_DOWN = "UP_AND_DOWN",
	BACK_TO_MAX = "BACK_TO_MAX",
	TOKEN_TRIAL = "TOKEN_TRIAL",
	TOUR_GUIDE = "TOUR_GUIDE",
	UNIVERSE_FILTER = "UNIVERSE_FILTER",
	ALERT = "ALERT",
	PRINT = "PRINT",
	IBBOTTSON = "IBBOTTSON",
	PTF_EXPORT_CSV = "PTF_EXPORT_CSV",
	COMPARE_OPTIMIZATION = "COMPARE_OPTIMIZATION",
	SEMIPRO_DISTRIBUTION_QUERY = "SEMIPRO_DISTRIBUTION_QUERY",
	COMPARE_W_FUNCTION = "COMPARE_W_FUNCTION",
	COMPARE_W_COMMISSION = "COMPARE_W_COMMISSION",
	COMPARE_W_METRICS = "COMPARE_W_METRICS"
}

export type Features = Partial<{ [key in Feature]: boolean }>;

export const LEGACY_STAGE_ENDPOINT = "https://stage.api.empirich.com";
export const HETZNER_STAGE_ENDPOINT = "https://stage.api.empirich.com";
export const STAGE_ENDPOINT = HETZNER_STAGE_ENDPOINT;
export const LEGACY_PROD_ENDPOINT = "https://api.empirich.com";
export const HETZNER_PROD_ENDPOINT = "https://api.empirich.com";

export const PROD_ENDPOINT = HETZNER_PROD_ENDPOINT;
export const STAGE_FRONTEND = "https://stage.empirich.com";
export const PROD_FRONTEND = "https://v2.empirich.com";

interface configApp {
	URL: string;
	FRONTEND: string;
	DANIKA_HOST: string;
	DANIKA_MASTER_HOST: string;
	MAINANTENCE_MODE: boolean;
	WS: string;
	STRIPE_TOKEN: string;
	ES: string;
	STAGE: STAGE;
	FEATURES: Features;
}

function getURL(stage: STAGE): string {
	switch (stage) {
		case "production":
			return PROD_ENDPOINT;
		case "mes":
			return PROD_ENDPOINT;
		case "stage":
			return STAGE_ENDPOINT;
		case "localhost":
			return "http://localhost:8000";
		default:
			throw new Error("Non hai settato nulla per questo stage");
	}
}

function getFrontend(stage: STAGE): string {
	switch (stage) {
		case "production":
			return PROD_FRONTEND;
		case "mes":
			return PROD_FRONTEND;
		case "stage":
			return STAGE_FRONTEND;
		case "localhost":
			return "http://localhost:3000";
		default:
			throw new Error("Non hai settato nulla per questo stage");
	}
}

type STAGE = "production" | "localhost" | "stage" | "test" | "mes";

function getStripeToken(): string {
	switch (import.meta.env.MODE) {
		case "production":
			// TODO: striep token di test, throw new Error("non sono pronto per la produzione");
			return "pk_live_51HWluoHpI0eEacEF5vcW3SZkujXQR4otiBCH9LIfqqXJM8LdpBQTAmD3XyYHXg45GG4UppBV9DsDUdLTTX3jkbYa00pnprZKZS";
		case "stage":
			// todo: questo è un test token da cambiare.
			return "pk_test_51HWluoHpI0eEacEFhpmgDIXsqc3Tu7AKKpEfBfyqAnMnxmLuHwLQxQmaBEFbplw8DhQHeDIfjvhcjzfOtCAkTtH100chm1iEGs";
		default:
			return "pk_test_51HWluoHpI0eEacEFhpmgDIXsqc3Tu7AKKpEfBfyqAnMnxmLuHwLQxQmaBEFbplw8DhQHeDIfjvhcjzfOtCAkTtH100chm1iEGs";
	}
}
function getFeatures(): Features {
	const isPremiumFeatureEnabled =
		(window as any)["PREMIUM_FEATURE_ENABLED"] === "true";
	if (getStatusStage() === "stage") {
		return (
			Object.keys(Feature)
				// .filter((f) => f !== Feature.GARFIELD)
				.reduce(
					(prev, next) => ({
						...prev,
						[next]: next === Feature.PREMIUM ? isPremiumFeatureEnabled : true,
					}),
					{},
				) as any
		);
	}
		return {
			[Feature.PREPAID]: true,
			[Feature.PREMIUM]: true,
			[Feature.GARFIELD]: true,
			[Feature.TOKEN_TRIAL]: true,
			[Feature.HINT_OVERLAY]: true,
			[Feature.SINGLE_SESSION]: true,
			[Feature.BOOKMARKS]: true,
			[Feature.SAVED_QUERIES]: true,
			[Feature.UNIVERSE_FILTER]: true,
			[Feature.LISTS]: true,
			[Feature.PRINT]: true,
			[Feature.UP_AND_DOWN]: true,
			[Feature.DANIKA_SEMI_PRO]: true,
			[Feature.IBBOTTSON]: false,
			[Feature.PTF_EXPORT_CSV]: true,
			[Feature.SIMULATION_METRICS]: true,
			[Feature.COMPARE_OPTIMIZATION]:true,
			[Feature.SEMIPRO_DISTRIBUTION_QUERY]: true,
			[Feature.COMPARE_W_FUNCTION]: true,
			[Feature.ALERT]: true,
			[Feature.COMPARE_W_COMMISSION]: false,
			[Feature.COMPARE_W_METRICS]:false
		};
}

export const FEATURE_OVERRIDE_KEY = "override_premium_feature";

export function isFeatureEnabled(feature: Feature, force?: boolean): boolean {
	const features = config().FEATURES;
	if (force === true) {
		return true;
	}

	if (feature === Feature.PREMIUM) {
		if (window.localStorage.getItem(FEATURE_OVERRIDE_KEY) === "true") {
			return true;
		}
	}

	return features[feature] === true;
}

function getES(stage: STAGE): string {
	switch (stage) {
		case "production":
			return `${PROD_ENDPOINT}/elastic/`;
		case "stage":
			// todo: questo è un test token da cambiare.
			return `${STAGE_ENDPOINT}/elastic/`;
		case "localhost":
			return "http://localhost:19200";
		case "mes":
			return `${PROD_ENDPOINT}/elastic/`;
		default:
			throw new Error("Non hai settato nulla per questo stage");
	}
}
export const getStatusStage = (): STAGE => import.meta.env.MODE as STAGE;

export const config = (): configApp => {
	if (import.meta.env.MODE === "test") {
		return {
			URL: "/",
			DANIKA_HOST: "api.danika.empirich.com",
			DANIKA_MASTER_HOST: "danika.server2.empirich.com",
			FRONTEND: "/",
			MAINANTENCE_MODE,
			WS: "ws://localhost:12345",
			STRIPE_TOKEN: "",
			ES: "/",
			STAGE: "test",
			FEATURES: getFeatures(),
		};
	}

	if (import.meta.env.MODE === "stage" || import.meta.env.MODE === "production")
		return {
			URL: getURL(getStatusStage()),
			FRONTEND: getFrontend(getStatusStage()),
			DANIKA_HOST: "server.danika2.empirich.com",
			DANIKA_MASTER_HOST: "master.danika.empirich.com",
			WS: getURL(getStatusStage())
				.replace("https://", "wss://")
				.replace("http://", "ws://"),
			STRIPE_TOKEN: getStripeToken(),
			MAINANTENCE_MODE,
			ES: getES(getStatusStage()),
			STAGE: getStatusStage(),
			FEATURES: getFeatures(),
		};

	throw new Error("env error");
};

export function getColorPalette() {
	return [
		"#1f77b4",
		"#ff7f0e",
		"#2ca02c",
		// "#d62728",
		"#9467bd",
		"#8c564b",
		"#e377c2",
		"#7f7f7f",
		"#bcbd22",
		"#17becf",
	];
}

export function getColorPaletteAtIndex(index: number): string {
	const palette = getColorPalette();
	return palette[index % palette.length];
}

export function hexToRgb(hex: string) {
	var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? {
				r: parseInt(result[1], 16),
				g: parseInt(result[2], 16),
				b: parseInt(result[3], 16),
		  }
		: null;
}
