import { getColorPaletteAtIndex } from "config";
import { PortfolioWithFunction } from "contorller/portfolio/types";
import { compact } from "lodash";
import Matrix from "ml-matrix";
import { useTranslation } from "react-i18next";
import { commissionsSum, INVESTED_DEFLATED, INVESTED_FIXED_RATE } from "simulator/types";
import { fromDaysToString } from "utils/portfolios";
import { getAbsoluteYield, getLastAmount } from "../pic/earnTable";

export function useGetLegendItemsCompare() {
  const { t } = useTranslation();
  return (
    isRealPic: boolean,
    data: Array<Matrix>,
    portfolios?: PortfolioWithFunction[],
    annualCommission?:number
  ): FundLegendItem[] => {
    if (portfolios == null || portfolios.length === 0) {
      return [];
    }
    const legends: FundLegendItem[] = [];
    for (let i = 0; i < portfolios.length; i++) {
      const nameAccessories: string[] = [];
      if (portfolios[i].functionType && portfolios[i].functionDaysPeriod) {
        nameAccessories.push(`(${t(`AlertGoalType_${portfolios[i].functionType}_short`)}${fromDaysToString(portfolios[i].functionDaysPeriod ?? 0)})`);
      }
      if (portfolios[i].annualCommission || annualCommission) {
        nameAccessories.push(`(${t("Comm. Annua")}: ${commissionsSum([annualCommission, portfolios[i].annualCommission])?.toFixed(2)}%)`);
      }
      const p: FundLegendItem = {
        id: portfolios[i].compareId ?? portfolios[i].id,
        name: portfolios[i].name,
        type: portfolios[i].isDanika === true || portfolios[i].viewers?.length > 0 ? "portfolio": "fund",
        amount: getLastAmount(data[i]),
        yield: getAbsoluteYield(data[i]),
        color: getColorPaletteAtIndex(i+3),
        nameAccessories: nameAccessories.length > 0 ? nameAccessories: undefined
      };
      legends.push(p)
    }

    const realPic: FundLegendItem[] = isRealPic
      ? [
          {
            id: "invested",
            name: t("Investito"),
            type: "invested",
            amount: getLastAmount(data[0], INVESTED_DEFLATED),
            yield: 0, //getAbsoluteYield(data, INVESTED, INVESTED_DEFLATED),
            color: getColorPaletteAtIndex(1),
          },
          {
            id: "invested-fixed-rate",
            name: t("Investito Tasso Fisso"),
            type: "invested",
            amount: getLastAmount(data[0], INVESTED_FIXED_RATE),
            yield: 0,
            color: getColorPaletteAtIndex(2),
          },
        ]
      : [];
    return compact([...legends, ...realPic]);
  };
}

export interface FundLegendItem {
  id: string;
  name: string;
  type: "fund" | "portfolio" | "invested";
  yield: number;
  color: string;
  amount: number;
  nameAccessories?: string[];
}
